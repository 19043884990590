import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField
} from '@material-ui/core';
import { addGift, clearGift } from '../actions/giftactions';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import languageJson from '../config/language';
import { makeStyles } from '@material-ui/core/styles';
import UsersCombo from '../components/UsersCombo';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: '100%'
  }
}));

export default function AddGift() {
  const getDateString = (date) => {
    let d = null;
    d = date ? new Date(date) : new Date();
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      mins = d.getMinutes();
    if (month >= 1 & month <= 9)
      month = '0' + month.toString();
    if (day >= 0 & day <= 9)
      day = '0' + day.toString();
    if (hours >= 0 & hours <= 9)
      hours = '0' + hours.toString();
    if (mins >= 0 & mins <= 9)
      mins = '0' + mins.toString();
      return [year, month, day].join('-');
  }
  const dispatch = useDispatch();
  const classes = useStyles();
  const userdata = useSelector(state => state.usersdata);
  const [giftModalStatus, setGiftModalStatus] = React.useState(false);
  const [users, setUsers] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [userCombo, setUserCombo] = useState(null);
  const [promoType, setType] = useState(null);
  const [startDate, setStartDate] = React.useState(getDateString());
  const [endDate, setEndDate] = React.useState(getDateString());
  const [maxPromo, setMaxPromo] = useState(null);
  const [minOrder, setMinOrder] = useState(null);
  const [promoDiscount, setPromoDiscount] = useState(null);
  const [promoUsage, setUsage] = useState(null);
  
  const onDateChangeStart = (event) => {
    setStartDate(event.target.value);
  };

  const onDateChangeEnd = (event) => {
    setEndDate(event.target.value);
  };

  const handleTypeSelect = (event) => {
    setType(event.target.value);
  };

  const handleMaxPromo = (event) => {
    setMaxPromo(event.target.value);
  };

  const handleMinOrder = (event) => {
    setMinOrder(event.target.value);
  };

  const handlePromoDiscount = (event) => {
    setPromoDiscount(event.target.value);
  };

  const handleUsage = (event) => {
    setUsage(event.target.value);
  };

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'rider') {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + user.mobile + ') ' + user.email
          });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users]);

  const handleGetGift = (e) => {
    e.preventDefault();
    if (userCombo && maxPromo && minOrder && promoType && promoDiscount && promoUsage) {
      setGiftModalStatus(true);
    } else {
      setCommonAlert({ open: true, msg: languageJson.complete_proper })
    }
  };

  const confirmGift = (e) => {
    e.preventDefault();
    setGiftModalStatus(false);
    dispatch(addGift({
      max_promo_discount_value: maxPromo,
      min_order: minOrder,
      promo_description: ' (' + userCombo.mobile + ') ' + userCombo.email,
      promo_discount_type: promoType,
      promo_discount_value: promoDiscount,
      promo_name: userCombo.firstName + ' ' + userCombo.lastName,
      promo_start: getFormatDate(startDate),
      promo_validity: getFormatDate(endDate), 
      promo_usage_limit: promoUsage,
      user_avail: userCombo.uid
    }));
    dispatch(clearGift());
    clearForm();
    listGifts();
  };

  const handleGiftModalClose = (e) => {
    e.preventDefault();
    setGiftModalStatus(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const clearForm = () => {
    setUserCombo(null);
    setType(null);
    setMaxPromo(null);
    setMinOrder(null);
    setPromoDiscount(null);
    setUsage(null);
  }

  const listGifts = () => {
    window.location.href = './giftcardsdriver';
  }

  const getFormatDate = (date) => {
    let datestring = date.split('T')[0],
    year = datestring.split('-')[0],
    month = datestring.split('-')[1],
    day = datestring.split('-')[2];
    return day + '/' + month + '/' + year; 
  }

  return (
    <div className={classes.container}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
              {languageJson.addgift_driver}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {users ?
              <UsersCombo
                className={classes.items}
                placeholder={languageJson.select_user}
                users={users}
                value={userCombo}
                onChange={(event, newValue) => {
                  setUserCombo(newValue);
                }}
              />
              : null}
          </Grid>
          <Grid item xs={12}>
               <Select
                  id="promo_discount_type"
                  onChange={handleTypeSelect}
                  variant="outlined"
                  fullWidth
                  value={promoType}
                  className={classes.input}
                  defaultValue={languageJson.promo_type}
                  label={languageJson.promo_type}
                >
                  <MenuItem key="flat" value="flat">
                   {languageJson.flat}
                  </MenuItem>
                  <MenuItem key="percentage" value="percentage">
                    {languageJson.percentage}
                  </MenuItem>
                </Select>
          </Grid>
          <Grid item xs={12}>
               <TextField
                  id="promo_discount_value"
                  label={languageJson.promo_discount_value}
                  variant="outlined"
                  fullWidth
                  className={classes.commonInputStyle}
                  InputProps={{
                    className: classes.input
                  }}
                  value={promoDiscount}
                  onChange={handlePromoDiscount}
                />
          </Grid>
          <Grid item xs={12}>
               <TextField
                id="max_promo_discount_value"
                label={languageJson.max_limit}
                variant="outlined"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input
                }}
                value={maxPromo} 
                onChange={handleMaxPromo}
              />
          </Grid>
          <Grid item xs={12}>
               <TextField
                  id="min_order"
                  label={languageJson.min_limit}
                  variant="outlined"
                  fullWidth
                  className={classes.commonInputStyle}
                  InputProps={{
                    className: classes.input
                  }}
                  value={minOrder}
                  onChange={handleMinOrder}
                />
          </Grid>
          <Grid item xs={12} sm={6}>
             <TextField
                id="promo_start"
                label={languageJson.start_date}
                type="date"
                variant="outlined"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input
                }}
                value={startDate}
                onChange={onDateChangeStart}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                id="promo_validity"
                label={languageJson.end_date}
                type="date"
                variant="outlined"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input
                }}
                value={endDate}
                onChange={onDateChangeEnd}
              />
          </Grid>
          <Grid item xs={12}>
               <TextField
                  id="promo_usage_limit"
                  label={languageJson.promo_usage}
                  variant="outlined"
                  fullWidth
                  className={classes.commonInputStyle}
                  InputProps={{
                    className: classes.input
                  }}
                  value={promoUsage}
                  onChange={handleUsage}
                />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              size="large"
              onClick={handleGetGift}
              variant="contained" 
              color="primary"
              className={classes.buttonStyle}
            >
              {languageJson.addgift}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={giftModalStatus}
        onClose={handleGiftModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{languageJson.addgift}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {languageJson.confirm_gift}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGiftModalClose} color="primary">
            {languageJson.cancel}
          </Button>
          <Button onClick={confirmGift} color="primary" autoFocus>
            {languageJson.addgift}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}