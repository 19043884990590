import { promoRef, promoEditRef} from "../config/firebase";
import { 
    FETCH_GIFTS,
    FETCH_GIFTS_SUCCESS,
    FETCH_GIFTS_FAILED,
    EDIT_GIFTS,
    CONFIRM_GIFT,
    CONFIRM_GIFT_SUCCESS,
    CLEAR_GIFT
} from "./types";

export const fetchGifts = () => dispatch => {
    dispatch({
      type: FETCH_GIFTS,
      payload: null
    });
    promoRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        var renderobj = {}
        Object.keys(data).map(i => {
          if(data[i].user_avail !== '') {
            data[i].id = i
            renderobj[i] = data[i]
          }
          return null
        });
        if(renderobj) {
          const arr = Object.keys(renderobj).map(i => {
           return renderobj[i]
          })
          dispatch({
            type: FETCH_GIFTS_SUCCESS,
            payload: arr
          });
        }
      } else {
        dispatch({
          type: FETCH_GIFTS_FAILED,
          payload: "Sin promos"
        });
      }
    });
  };

  export const editGift = (promos,method) => dispatch =>{
    dispatch({
      type: EDIT_GIFTS,
      payload: method
    });
    if(method === 'Add' ){
      promoRef.push(promos);
    }else if(method === 'Delete'){
      console.log(promos)
      promoEditRef(promos.id).remove();
    }else{
      console.log(promos)
      promoEditRef(promos.id).set(promos);
    }
  }

  export const addGift = (giftData) => (dispatch) => {
    dispatch({
        type: CONFIRM_GIFT,
        payload: giftData,
    });

    var data = {
      createdAt: new Date().toISOString(),
      max_promo_discount_value: giftData.max_promo_discount_value,
      min_order: giftData.min_order,
      promo_description: giftData.promo_description,
      promo_discount_type: giftData.promo_discount_type,
      promo_discount_value: giftData.promo_discount_value,
      promo_name: giftData.promo_name,
      promo_start: giftData.promo_start,
      promo_usage_limit: giftData.promo_usage_limit,
      promo_used_by: 0,
      promo_validity: giftData.promo_validity,
      user_avail: giftData.user_avail
    }

    promoRef.push(data).then((res) => {
      var giftKey = res.key;
      promoRef().set(data).then(() => {
        promoEditRef(giftKey).update({
          id: giftKey
        }).then(() => {
            dispatch({
              type: CONFIRM_GIFT_SUCCESS,
              payload: {
                  id: giftKey
              }    
            });
        })
      });
    });
  }

  export const clearGift = () => async (dispatch) => {
    dispatch({
        type: CLEAR_GIFT,
        payload: null,
    });
  }