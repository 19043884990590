import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from "./reducers/store";
import ProtectedRoute from './components/ProtectedRoute';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import CarTypes from './views/CarTypes';
import AddBookings from './views/AddBookings';
import AddGift from './views/AddGift';
import AddGiftDriver from './views/AddGiftDriver';
import BookingHistory from './views/BookingHistory';
import BookingAlert from './views/BookingAlert';
import Promos from './views/Promos';
import PromosDriver from './views/PromosDriver';
import GiftCards from './views/GiftCards';
import GiftCardsDriver from './views/GiftCardsDriver';
import Drivers from './views/Drivers';
import Riders from './views/Riders';
import FleetAdmins from './views/FleetAdmins';
import CreateAdmin from './views/CreateAdmin';
import CancellationReasons from './views/CancellationReasons'
import Referral from './views/Referral';
import {fetchUser}  from "./actions/authactions";
import AuthLoading from './components/AuthLoading';
import Notifications from './views/Notifications';
import DriverEarning from './views/DriverEarning';
import EarningReports from './views/EarningReports';
import MyProfile from './views/MyProfile'
import Settings from './views/Settings';
import {createTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {esES} from '@material-ui/core/locale';

const theme = createTheme({
  palette: {
    primary: {
      main: '#c20b19',
    },
    secondary: {
      main: '#c5c4c4',
    },
  },
},esES);

function App() {
  store.dispatch(fetchUser());
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
      <AuthLoading>
        <Router>
          <Switch>
            <ProtectedRoute exact component={Dashboard} path="/"/>
            <ProtectedRoute exact component={CarTypes} path="/cartypes"/>
            <ProtectedRoute exact component={AddBookings} path="/addBookings"/>
            <ProtectedRoute exact component={AddGift} path="/addGift"/>
            <ProtectedRoute exact component={AddGiftDriver} path="/addGiftDriver"/>
            <ProtectedRoute exact component={BookingHistory} path="/bookings"/>
            <ProtectedRoute exact component={BookingAlert} path="/bookingsalert"/>
            <ProtectedRoute exact component={Promos} path="/promos"/>
            <ProtectedRoute exact component={PromosDriver} path="/promosdriver"/>
            <ProtectedRoute exact component={GiftCards} path="/giftcards"/>
            <ProtectedRoute exact component={GiftCardsDriver} path="/giftcardsdriver"/>
            <ProtectedRoute exact component={Drivers} path="/drivers"/>
            <ProtectedRoute exact component={FleetAdmins} path="/fleetadmins"/>
            <ProtectedRoute exact component={CreateAdmin} path="/alladmins"/>
            <ProtectedRoute exact component={CancellationReasons} path="/cancelreasons"/>
            <ProtectedRoute exact component={Riders} path="/riders"/>
            <ProtectedRoute exact component={DriverEarning} path="/driverearning"/>
            <ProtectedRoute exact component={Referral} path="/referral"/>
            <ProtectedRoute exact component={Notifications} path="/notifications"/>
            <ProtectedRoute exact component={EarningReports} path="/earningreports"/>
            <ProtectedRoute exact component={Settings} path="/settings"/>
            <ProtectedRoute exact component={MyProfile} path="/profile"/>
            <Route component={Login} path="/login"/>
          </Switch>
        </Router>
      </AuthLoading>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
