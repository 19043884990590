import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from "../config/language";
import { cancelBooking } from '../actions/bookinglistactions';
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import { tripStatus, paymentStatus, isLive } from '../config/keys';
import { dateStyle, dateStyleMoment } from '../config/dateStyle';
import {localization} from '../config/materialTable';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

const BookingAlert = () => {
  const dispatch = useDispatch();
  
  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const [settings,setSettings] = useState({});
  
  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

  
  const columns =  [
      { title: languageJson.booking_date, field: 'tripdate', render: rowData => rowData.tripdate ? new Date(rowData.tripdate).toLocaleString(dateStyleMoment):null, defaultSort:'desc'},
      { title: languageJson.car_type, field: 'carType' },
      { title: languageJson.customer_name,field: 'customer_name'},
      { title: languageJson.pickup_address, field: 'pickupAddress' },
      { title: languageJson.drop_address, field: 'dropAddress' },
      { title: languageJson.assign_driver, field: 'driver_name' },
      { title: languageJson.booking_status, field: 'status', render: rowData => tripStatus[rowData.status] },
      { title: languageJson.trip_cost, field: 'trip_cost',  render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.trip_cost).toFixed(0)).format() },
      { title: languageJson.discount_ammount, render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.discount).toFixed(0)).format()}, 
      { title: languageJson.Customer_paid, render: rowData => rowData.customer_paid > 0 ? settings.symbol + ' ' + numbro(parseFloat(rowData.customer_paid).toFixed(0)).format() : settings.symbol + ' ' + rowData.customer_paid },
      { title: languageJson.payment_status, field: 'payment_status', render: rowData => paymentStatus[rowData.payment_status]}
      
  ];
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState('');
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  
  useEffect(()=>{
        if(bookinglistdata.bookings){
            setData(bookinglistdata.bookings.filter(bookings => bookings.isReport === true));
        }
  },[bookinglistdata.bookings]);

  const onConfirmClose=(value)=>{
    if(value){
      dispatch(cancelBooking({
        reason:value,
        booking:selectedBooking
      }));
    }
    setOpenConfirm(false);
  }
  
  return (
    bookinglistdata.loading? <CircularLoading/>:
    <div>
    <MaterialTable
      localization={localization}
      title={languageJson.booking_alert}
      columns={columns}
      data={data.reverse()}
      options={{
        actionsColumnIndex: -1,
        columnsButton: true
      }}
      actions={[
        rowData => ({
          icon: 'cancel',
          tooltip: languageJson.cancel_booking,
          disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED'? false:true,
          onClick: (event, rowData) => {
            if(isLive){
              setSelectedBooking(rowData);
              setOpenConfirm(true);
            }else{
              alert('Restricted in Demo App.');
            }
          }         
        }),
      ]}
    />
    <ConfirmationDialogRaw
      open={openConfirm}
      onClose={onConfirmClose}
      value={''}
    />
    </div>

  );
}

export default BookingAlert;
