export const localization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'filas'
  },
  toolbar: {
      nRowsSelected: '{0} fila(s) seleccionados',
      exportTitle: 'Exportar',
      exportAriaLabel: 'Exportar',
      exportName: 'Exportar como CSV',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
      addRemoveColumns: 'Agregar o eliminar columnas',
      showColumnsTitle: 'Ver columnas'
  },
  header: {
      actions: 'Acciones'
  },
  body: {
      emptyDataSourceMessage: 'Sin datos',
      addTooltip: 'Agregar',
      deleteTooltip: 'Eliminar',
      editTooltip: 'Editar',
      editRow: {
          deleteText: '¿Desea eliminar esta fila?',
          cancelTooltip: 'Cancelar',
          saveTooltip: 'Guardar'
      },
      filterRow: {
          filterTooltip: 'Filtrar'
      }
  },
  grouping: {
      placeholder: 'Arrastra los encabezados aquí para agruparlos por'
  }
};