import { cancelreasonRef } from "../config/firebase";
import {
  FETCH_CANCEL_REASONS,
  FETCH_CANCEL_REASONS_SUCCESS,
  FETCH_CANCEL_REASONS_FAILED,
} from "./types";

import languageJson from '../config/language';

export const fetchCancelReasons= () => (dispatch) => {
  dispatch({
    type: FETCH_CANCEL_REASONS,
    payload: null,
  });
  cancelreasonRef.on("value", (snapshot) => {
    if (snapshot.val()) {
      dispatch({
        type: FETCH_CANCEL_REASONS_SUCCESS,
        payload: snapshot.val(),
      });
    } else {
      dispatch({
        type: FETCH_CANCEL_REASONS_FAILED,
        payload: languageJson.no_cancel_reason,
      });
    }
  });
};
